import { Alert, AlertDescription, AlertIcon, AlertTitle, Text, VStack } from "@chakra-ui/react";

export default function ResultsBox() {
  return (
    <VStack justify="space-between">
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="400"
        bg="green.50"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Payment Complete
        </AlertTitle>
        <AlertDescription maxWidth="sm">Thank you! Your payment is successful.</AlertDescription>
      </Alert>
      <Text fontSize="sm" fontStyle="italic" color="gray.500">
        This communication is from a debt collector. This is an attempt to collect a debt. Any
        information obtained will be used for that purpose. Making a payment today does not
        overshadow the rights provided to you in your initial notice.
      </Text>
    </VStack>
  );
}
